import React from 'react';

const Home = () => {
    return (
        <section>
        <h1>Kontakt</h1>
        <h2>SKŁAD OPAŁU „PAJKA”</h2>
        <p>41-404 Mysłowice</p>
<p>Ul. Fabryczna 7</p>
<p>Telefon: 695-394-783, 601-739-568</p>
<p>E-mail: <a href="mailto:andrzej.pajka@gmail.com">andrzej.pajka@gmail.com</a></p>
<p>NIP: 954-15-87-180</p>     
        </section>
        );
    }

    export default Home
