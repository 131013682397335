import React from "react";

const Prices = () => {
  return (
    <section className="center">
      <p>Szanowni Państwo informujemy, iż posiadamy samochody o pojemności do 2t, 4t, 8t oraz powyżej. </p> 
      <p> Wszelkie szczegóły dostawy są omawiane indywidualnie, by jak nalepiej dopasować się do Państwa potrzeb.</p>
      <h1> Cennik </h1>

      {/* <h1 class = "info"> UWAGA! W związku z dynamiczną sytuacją na rynku, ceny na stronie są cenami <strong>poglądowymi </strong> </h1>
      <h1 class ="info"> Najbardziej aktualne ceny otrzymają Państwo podczas kontaktu telefonicznego</h1>
      <h2 class="info">Ceny staramy sie aktualizować na stronie jak najszybciej</h2> */}
      
      <div>
        <table>
          <thead>
            <tr>
              <th>Nazwa asortymentu</th>
              <th>Cena brutto zł/t - węgiel luzem</th>
              <th>Cena brutto zł/t - węgiel workowany</th>
            </tr>
          </thead>
          <tbody>
           

            <tr>
              <td>Ekogroszek Staszic</td>
              <td> 1450 zł</td>
              <td> 1550 zł</td>
            </tr>

            <tr>
              <td>Ekogroszek Ziemowit</td>
              <td> - zł</td>
              <td> - zł</td>
            </tr>

            <tr>
              <td>Ekogroszek Wesoła</td>
              <td>1450 zł</td>
              <td>1550 zł</td>
            </tr>

            <tr>
              <td>Orzech Wesoła</td>
              <td>- zł</td>
              <td>- zł</td>
            </tr>

            <tr>
              <td>Ekogroszek Silesia</td>
              <td> - zł</td>
              <td> - zł</td>
            </tr>

            <tr>
              <td>Ekogroszek Karlik</td>
              <td> - zł</td>
              <td> - zł</td>
            </tr>
          </tbody>
        </table>
      </div>
    </section>
  );
};

export default Prices;
