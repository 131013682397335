import React from 'react';

const Foot = () => {
    
    return (
        
        <>
        
<p> <span>Copyright:</span> Sklad Opału Pajka </p>
<p>Telefon: 695-394-783, 601-739-568</p>

        
        </>
        );
    }

export default Foot
