import React, { Component } from 'react';
import Home from "./Home";
import Prices from "./Prices";
import Foot from "./Footer";
import Contact from "./Contact";
import Cookies from "./CookiesPolicy";
import './App.css';
import { BrowserRouter as Router, NavLink, Route, Switch } from 'react-router-dom';
import CookieConsent from "react-cookie-consent";




const ErrorPage = () => <h1>Strona nie istnieje</h1>


class App extends Component {
  render() {
    return (
      <Router>
        <div className="container">
          <header>
          <div className="logo"></div> 
            <nav>
              <ul>
                <li><NavLink to="/" exact activeClassName="selected"
                >O nas</NavLink></li>
                <li><NavLink to="/cennik" activeClassName="selected">Cennik</NavLink></li>
                <li><NavLink to="/kontakt" activeClassName="selected">Kontakt</NavLink></li>
              </ul>
            </nav>
          </header>
          <main>
            <Switch>
              <Route path="/" exact component={Home} />
              <Route path="/cennik" component={Prices} />
              <Route path="/kontakt" component={Contact} />
              <Route path="/cookies" component={Cookies} />
              <Route component={ErrorPage} />
            </Switch>
          </main>
          <CookieConsent
          className="cookies"
    location="top"
    buttonText="Akceptuję"
    cookieName="myAwesomeCookieName2"
    style={{ background: "#8a8a8a", width:"60vw", left:"20%", opacity:"0.8" }}
    buttonStyle={{ color: "#fff", fontSize: "13px" }}
    expires={150}>

Ta strona korzysta z ciasteczek aby świadczyć usługi na najwyższym poziomie. Dalsze korzystanie ze strony, oznacza, że zgadzasz się na ich użycie. 
    <NavLink to="/cookies" style={{ fontSize: "10px", color:"#ffff66" }} >Polityka Cookies</NavLink>
    
</CookieConsent>
          <footer>
            <Foot/>
            <NavLink to="/cookies" style={{ fontSize: "10px", color:"#ffff66" }} >Polityka Cookies</NavLink>
          </footer>
        </div>
      </Router>
    );
  }
}

export default App;
