import React from "react";

const Home = () => {
  
  return (
    
    <section className="about">
      {/* <h1 style={{'background-color':'powderblue', 'color':'yellow'}}> UWAGA: w okresie 26.07.2021 - 03.08.2021 skład będzie nieczynny</h1>, */}
      <h1>O NAS</h1>
      <h2>Sprzedaż hurtowa i detaliczna</h2>
      <p>
        Nasz skład opału specjalizuje się w sprzedaży opału najwyższej jakości w
        konkurencyjnych cenach. Oferujemy naszym klientom, w ciągłej sprzedaży,
        każdy asortyment węgla, z polskich kopalni.
      </p>
      <p>
        Swoją ofertę kierujemy do klientów indywidualnych jak i hurtowych (małe
        firmy, przedsiębiorstwa, wspólnoty mieszkaniowe, gospodarstwa ogrodnicze
        i wiele innych).
      </p>
      <p>
        Naszymi największymi zaletami są: profesjonalna obsługa klienta,
        świetnie wyszkolona kadra pracownicza, terminowe dostawy.
      </p>

      <strong>Serdecznie zapraszamy!</strong>
    </section>
  );
};

export default Home;
